<template>
  <div class="app flex-row align-items-center login-page animated fadeIn">
    <loader />

    <Header class="fixed-top login-header" :class="{'d-none': isTyping && $mq !== 'lg'}" fixed>
      <b-link class="mx-auto col-md-4" to="#">
        <img class="navbar-brand-full img-fluid" src="img/brand/logo.png" alt="Taylor Logo" />
      </b-link>
      <div class="mx-auto col-md-12 text-center" v-if="isTestEnv">
        <span class="badge badge-warning p-2"><i class="fa fa-exclamation-circle"></i> Ambiente de Teste</span>
      </div>
    </Header>

    <div class="container">
      <b-row class="justify-content-center">
        <b-col md="5">
          <b-card-group>
            <b-card no-body class="p-4 no-bg no-border">
              <b-card-body>
                <b-form @submit.prevent="handleSubmit()">
                  <b-form-select class="mb-3 input-opacity" v-model="modo" :plain="true" :options="options" @change="clearForm"></b-form-select>
                  <b-input-group class="mb-3 input-opacity">
                    <b-input-group-prepend><b-input-group-text><i class="icon-globe"></i></b-input-group-text></b-input-group-prepend>
                    <b-form-input type="text" class="form-control" :placeholder="modo === 1 ? 'Grupo Empresarial' : 'Código Jetro'" id="txtGrupo" v-model="grupo" :formatter="limitFormat" autocomplete="off" @focus="isTyping = true" @blur="isTyping = false" :autofocus="!isMobile" />
                  </b-input-group>
                  <b-input-group class="mb-3 input-opacity" v-if="modo !== 2">
                    <b-input-group-prepend><b-input-group-text><i class="icon-user"></i></b-input-group-text></b-input-group-prepend>
                    <b-form-input type="text" class="form-control" placeholder="Login" v-model="login" autocomplete="off" @focus="isTyping = true" @blur="isTyping = false" />
                  </b-input-group>
                  <b-input-group class="mb-4 input-opacity">
                    <b-input-group-prepend><b-input-group-text><i class="icon-lock"></i></b-input-group-text></b-input-group-prepend>
                    <b-form-input type="password" class="form-control" placeholder="Senha" v-model="password" autocomplete="current-password" @focus="isTyping = true" @blur="isTyping = false" />
                  </b-input-group>
                  <b-row>
                    <b-col cols="12" class="text-center">
                      <b-button type="submit" variant="primary" class="px-4 text-uppercase text-white">Entrar</b-button>
                    </b-col>
                  </b-row>
                </b-form>
              </b-card-body>
            </b-card>
          </b-card-group>
        </b-col>
      </b-row>
    </div>

    <Footer class="login-footer fixed-bottom" :class="{'d-none': isTyping && $mq !== 'lg'}" login />
  </div>
</template>

<script>
import vSelect from 'vue-select'
import Header from '@/core/components/Header';
import Footer from '@/core/components/Footer';
import Loader from '@/core/components/Loader'
import session from '@/shared/session'
import swal from 'sweetalert';
import sha256 from 'sha256';
import leftPad from 'left-pad';
import API from '@/core/api';

const authAPI = new API('auth');

export default {
  name: 'Login',
  components: {
    Header,
    vSelect,
    Footer,
    Loader
  },
  data() {
    return {
      error: false,
      login: '',
      password: '',
      modo: 1,
      options: [
        { value: null, text: 'Selecione', disabled: true },
        { value: 1, text: 'Modo Gerencial' },
        { value: 2, text: 'Lista da Vez' }
      ],
      grupo: null,
      isTyping: false
    }
  },
  computed: {
    isTestEnv: function() {
      return window.location.host.includes('teste')
    },
    isMobile: function() {
      return ['sm', 'md'].includes(this.$mq)
    }
  },
  methods: {
    async handleSubmit() {
      const { grupo, modo, login, password } = this;

      if (!grupo || (modo !== 2 && !login) || !password) {
        return swal({
          title: 'Informação',
          text: 'Preencha os campos corretamente.',
          icon: 'info',
          dangerMode: true
        });
      }
      const {data} = await authAPI.post({
        modo, grupo, login, password,
        schema: `${grupo}`,
        secret: sha256(process.env.VUE_APP_SECRET)
      });

      if (data.token) {
        this.$store.dispatch("LOGIN", {
          grupo: data.adm_grupo_empres_id,
          modo,
          login,
          password,
          token: data.token,
          adm_estabel_centres_id: data.adm_estabel_centres_id,
          nome_estabel_centres: data.nome_estabel_centres
        });

        if (modo === 2) {
          return this.$router.push({path: '/lista-vez'});
        } else {
          if (data.nivel_colaborador === 2 && (!data.adm_estabels || (data.adm_estabels && data.adm_estabels.length === 0))) {
            return swal({
              title: 'Informação',
              text: 'Usuário com nível "Supervisão" sem lojas informadas. Verifique.',
              icon: 'info',
              dangerMode: true
            });
          }

          // Profile
          const profileAPI = new API('api/profile');
          const profile = await profileAPI.get({ login });
          this.$store.dispatch('PROFILE', profile.data[0]);

          // Lojas
          this.$store.dispatch('LOJAS', data.adm_estabels.map(l => {
            return {
              value: l.id,
              text: `${leftPad(l.id, 2, '0')}. ${l.nome}`,
              gestao: l.gestao
            }
          }));

          // Classes
          const classesAPI = new API('api/classes');
          const classes = await classesAPI.get();
          this.$store.dispatch('CLASSES', classes.data.map(l => {
            return {
              value: l.fd_classe,
              text: l.fd_descricao
            }
          }));

          // Classificações
          const classificacoesAPI = new API('api/classificacoes');
          const classificacoes = await classificacoesAPI.get();
          this.$store.dispatch('CLASSIFICACOES', classificacoes.data.map(l => {
            return {
              classe: l.fd_classe,
              value: l.fd_classificacao,
              text: l.fd_descricao
            }
          }));

          // Grupos
          const gruposAPI = new API('api/grupos');
          const grupos = await gruposAPI.get();
          this.$store.dispatch('GRUPOS', grupos.data.map(l => {
            return {
              value: l.fd_grupo,
              text: l.fd_nome
            }
          }));

          // Subgrupos
          const subgruposAPI = new API('api/subgrupos');
          const subgrupos = await subgruposAPI.get();
          this.$store.dispatch('SUBGRUPOS', subgrupos.data.map(l => {
            return {
              value: l.fd_subgrupo,
              text: l.fd_nome
            }
          }));

          // Fornecedores
          const fornecedoresAPI = new API('api/fornecedores');
          const fornecedores = await fornecedoresAPI.get();
          this.$store.dispatch('FORNECEDORES', fornecedores.data.map(l => {
            return {
              value: l.fd_fornecedor,
              text: (l.fd_nome || l.fd_razao_social)
            }
          }));

          // Vendedores
          const vendedoresAPI = new API('api/funcionarios');
          const vendedores = await vendedoresAPI.get();
          this.$store.dispatch('VENDEDORES', vendedores.data.map(l => {
            return {
              value: l.fd_funcionario,
              text: l.fd_nome
            }
          }));

          // Formas de Venda
          const formaAPI = new API('api/forma-vendas');
          const formas = await formaAPI.get();
          this.$store.dispatch('FORMA_VENDAS', formas.data.map(l => {
            return {
              value: l.cod,
              text: l.descricao
            }
          }));

          // Gestões
          const gestoesAPI = new API('api/gestoes');
          const gestoes = await gestoesAPI.get();
          this.$store.dispatch('GESTOES', gestoes.data.map(l => {
            return {
              value: l.fd_gestao,
              text: l.fd_descricao
            }
          }));

          // Clientes Atendidos - Evitar consulta repetida
          const clienteAPI = new API('api/total-clientes-grupo');
          const totalClientesGrupo = await clienteAPI.get();
          this.$store.dispatch('SET_TOTAL_CLIENTES', Number(totalClientesGrupo.data[0].total || 0));

          const groupsEnabled = process.env.NODE_ENV === 'production' ? [62, 173, 509, 577, 306, 108, 296, 526, 494, 148, 540] : [93, 384];
          if (groupsEnabled.includes(this.$store.state.grupo)) {
            // NPS Route
            this.$router.options.routes[2].children[10].meta.disabled = false
          } else {
            this.$router.options.routes[2].children[10].meta.disabled = true
          }

          return this.$router.push({path: '/home'});
        }
      }
      return swal({
        title: 'Informação',
        text: 'Login ou senha incorreto(s).',
        icon: 'warning',
        dangerMode: true
      });
    },
    clearForm() {
      this.grupo = ''
      this.login = ''
      this.password = ''
      document.getElementById('txtGrupo').focus()
    },
    limitFormat(value) {
      if (RegExp(/\d/g).test(value)) {
        return value;
      }
      return '';
    }
  },
  async created() {
    if (this.$store.state.token) {
      if (this.modo === 2) {
        this.$router.push({path: '/lista-vez'});
      } else {
        this.$router.push({path: '/home'});
      }
    }
  }
};
</script>
