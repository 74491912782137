var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "app flex-row align-items-center login-page animated fadeIn"
    },
    [
      _c("loader"),
      _c(
        "Header",
        {
          staticClass: "fixed-top login-header",
          class: { "d-none": _vm.isTyping && _vm.$mq !== "lg" },
          attrs: { fixed: "" }
        },
        [
          _c(
            "b-link",
            { staticClass: "mx-auto col-md-4", attrs: { to: "#" } },
            [
              _c("img", {
                staticClass: "navbar-brand-full img-fluid",
                attrs: { src: "img/brand/logo.png", alt: "Taylor Logo" }
              })
            ]
          ),
          _vm.isTestEnv
            ? _c("div", { staticClass: "mx-auto col-md-12 text-center" }, [
                _c("span", { staticClass: "badge badge-warning p-2" }, [
                  _c("i", { staticClass: "fa fa-exclamation-circle" }),
                  _vm._v(" Ambiente de Teste")
                ])
              ])
            : _vm._e()
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "container" },
        [
          _c(
            "b-row",
            { staticClass: "justify-content-center" },
            [
              _c(
                "b-col",
                { attrs: { md: "5" } },
                [
                  _c(
                    "b-card-group",
                    [
                      _c(
                        "b-card",
                        {
                          staticClass: "p-4 no-bg no-border",
                          attrs: { "no-body": "" }
                        },
                        [
                          _c(
                            "b-card-body",
                            [
                              _c(
                                "b-form",
                                {
                                  on: {
                                    submit: function($event) {
                                      $event.preventDefault()
                                      return _vm.handleSubmit()
                                    }
                                  }
                                },
                                [
                                  _c("b-form-select", {
                                    staticClass: "mb-3 input-opacity",
                                    attrs: {
                                      plain: true,
                                      options: _vm.options
                                    },
                                    on: { change: _vm.clearForm },
                                    model: {
                                      value: _vm.modo,
                                      callback: function($$v) {
                                        _vm.modo = $$v
                                      },
                                      expression: "modo"
                                    }
                                  }),
                                  _c(
                                    "b-input-group",
                                    { staticClass: "mb-3 input-opacity" },
                                    [
                                      _c(
                                        "b-input-group-prepend",
                                        [
                                          _c("b-input-group-text", [
                                            _c("i", {
                                              staticClass: "icon-globe"
                                            })
                                          ])
                                        ],
                                        1
                                      ),
                                      _c("b-form-input", {
                                        staticClass: "form-control",
                                        attrs: {
                                          type: "text",
                                          placeholder:
                                            _vm.modo === 1
                                              ? "Grupo Empresarial"
                                              : "Código Jetro",
                                          id: "txtGrupo",
                                          formatter: _vm.limitFormat,
                                          autocomplete: "off",
                                          autofocus: !_vm.isMobile
                                        },
                                        on: {
                                          focus: function($event) {
                                            _vm.isTyping = true
                                          },
                                          blur: function($event) {
                                            _vm.isTyping = false
                                          }
                                        },
                                        model: {
                                          value: _vm.grupo,
                                          callback: function($$v) {
                                            _vm.grupo = $$v
                                          },
                                          expression: "grupo"
                                        }
                                      })
                                    ],
                                    1
                                  ),
                                  _vm.modo !== 2
                                    ? _c(
                                        "b-input-group",
                                        { staticClass: "mb-3 input-opacity" },
                                        [
                                          _c(
                                            "b-input-group-prepend",
                                            [
                                              _c("b-input-group-text", [
                                                _c("i", {
                                                  staticClass: "icon-user"
                                                })
                                              ])
                                            ],
                                            1
                                          ),
                                          _c("b-form-input", {
                                            staticClass: "form-control",
                                            attrs: {
                                              type: "text",
                                              placeholder: "Login",
                                              autocomplete: "off"
                                            },
                                            on: {
                                              focus: function($event) {
                                                _vm.isTyping = true
                                              },
                                              blur: function($event) {
                                                _vm.isTyping = false
                                              }
                                            },
                                            model: {
                                              value: _vm.login,
                                              callback: function($$v) {
                                                _vm.login = $$v
                                              },
                                              expression: "login"
                                            }
                                          })
                                        ],
                                        1
                                      )
                                    : _vm._e(),
                                  _c(
                                    "b-input-group",
                                    { staticClass: "mb-4 input-opacity" },
                                    [
                                      _c(
                                        "b-input-group-prepend",
                                        [
                                          _c("b-input-group-text", [
                                            _c("i", {
                                              staticClass: "icon-lock"
                                            })
                                          ])
                                        ],
                                        1
                                      ),
                                      _c("b-form-input", {
                                        staticClass: "form-control",
                                        attrs: {
                                          type: "password",
                                          placeholder: "Senha",
                                          autocomplete: "current-password"
                                        },
                                        on: {
                                          focus: function($event) {
                                            _vm.isTyping = true
                                          },
                                          blur: function($event) {
                                            _vm.isTyping = false
                                          }
                                        },
                                        model: {
                                          value: _vm.password,
                                          callback: function($$v) {
                                            _vm.password = $$v
                                          },
                                          expression: "password"
                                        }
                                      })
                                    ],
                                    1
                                  ),
                                  _c(
                                    "b-row",
                                    [
                                      _c(
                                        "b-col",
                                        {
                                          staticClass: "text-center",
                                          attrs: { cols: "12" }
                                        },
                                        [
                                          _c(
                                            "b-button",
                                            {
                                              staticClass:
                                                "px-4 text-uppercase text-white",
                                              attrs: {
                                                type: "submit",
                                                variant: "primary"
                                              }
                                            },
                                            [_vm._v("Entrar")]
                                          )
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _c("Footer", {
        staticClass: "login-footer fixed-bottom",
        class: { "d-none": _vm.isTyping && _vm.$mq !== "lg" },
        attrs: { login: "" }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }